import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const CustomTextField = styled(TextField)`
    ${({ styleType }) => {
        let styles;
        switch (styleType) {
            case 'LoginTextField':
                styles = {
                    width: '540px ',
                    height: '65px !important',
                    paddingBottom: '15px !important',
                };
                break;
            case 'PasswordTextField':
                styles = {
                    width: '540px ',
                    height: '65px',
                    paddingBottom: '15px ',
                };
                break;
            case 'ModalTextField':
                styles = {
                    width: '340px !important',
                    height: '65px !important',
                    paddingBottom: '15px !important',
                };
                break;
            default:
                styles = {};
        }
        return styles;
    }}

    @media (max-width: 540px) {
        width: 300px !important;
    }
`;
