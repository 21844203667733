import * as Styled from './styles';

const CustomButton = ({ title, buttontype, type, sx, id, onClick }) => {
    return (
        <Styled.StyledButton buttontype={buttontype} type={type} onClick={onClick} sx={sx} id={id}>
            {title}
        </Styled.StyledButton>
    );
};

export default CustomButton;
