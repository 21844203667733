import * as Styled from "./styles";
import CoverLogo from "../../assets/images/loginBackground.png";
import CustomTextField from "../../components/textfield";
import CustomButton from "../../components/button";
import ImageFrame from "../../components/imageframe";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StyledAlerts from "../../components/alert";
import { setLoginCredentials } from "../../cache";

const LoginPage = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [username, setUsername] = useState("admin");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("admin");
  const navigate = useNavigate();

  const loginUser = () => {
    if (username === "admin" && password === "admin") {
      setLoginCredentials();
      navigateHandler("/admin/dashboard");
    } else {
      setMessage("please check username and password");
      setOpenAlert(true);
    }
  };

  const addNewEventListener = (elementIDS) => {
    elementIDS.map((id) => {
      document.getElementById(id)?.addEventListener("keypress", (event) => {
        if (event.keyCode === 13) loginUser();
      });
    });
  };

  useEffect(() => {
    addNewEventListener(["username", "password"]);
  }, [username, password]);

  const navigateHandler = (path) => {
    navigate(path);
  };

  const onClick = (event) => {
    event.preventDefault();
    loginUser();
  };

  return (
    <>
      {
        <StyledAlerts
          type="Error"
          message={message}
          openAlert={openAlert}
          setVisible={setOpenAlert}
        />
      }
      <Styled.LoginContainer>
        <Styled.LoginImageWrapper>
          <ImageFrame src={CoverLogo} />
        </Styled.LoginImageWrapper>
        <Styled.LoginContentWrapper>
          <Styled.LoginTitle>Login As Admin</Styled.LoginTitle>
          <Styled.LoginContentLabel width={540} height={25}>
            User Name
          </Styled.LoginContentLabel>
          <CustomTextField
            type="LoginTextField"
            placeHolder="Enter your username"
            onChange={({ target }) => setUsername(target.value)}
            id="username"
          />

          <Styled.LoginContentLabel width={540} height={25}>
            Password
          </Styled.LoginContentLabel>
          <CustomTextField
            type="PasswordTextField"
            placeHolder="Enter password"
            onChange={({ target }) => {
              setPassword(target.value);
            }}
            id="password"
          />

          <Styled.LoginButtonWrapper>
            <CustomButton
              buttontype="LoginButton"
              title="Sign In"
              onClick={onClick}
            />
          </Styled.LoginButtonWrapper>
          <Styled.LoginMetaTextWrapper>
            <Styled.LoginMetaText>Check train</Styled.LoginMetaText>
            <Styled.LoginMetaText
              color="#000000"
              onClick={() => navigateHandler("/")}
            >
              schedule ?
            </Styled.LoginMetaText>
          </Styled.LoginMetaTextWrapper>
        </Styled.LoginContentWrapper>
      </Styled.LoginContainer>
    </>
  );
};

export default LoginPage;
