import React from "react";
import Data from "../Data.json";

function ScheduleTable({ handleShow }) {
  const { schema } = Data;
  return (
    <div>
      <div className="mx-10 md:mx-40 mt-10 shadow-sm p-10 box-border rounded-md shadow-black">
        <div className="text-2xl font-bold mb-4">Search Train</div>
        <div className="my-div border-dotted border-2 border-gray-300"></div>
        <div className="my-5">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              handleShow(true);
            }}
          >
            Back
          </button>
        </div>
        <div className="shadow-sm rounded-md shadow-black p-10">
          <div className="flex flex-col  gap-10 ">
            <div className="mb-4 w-full overflow-auto">
              <table className="w-full whitespace-nowrap text-xs mb-2">
                <thead className="bg-[#91B9D3] text-black">
                  <tr>
                    <th className="px-4 py-2">Your Station</th>
                    <th className="px-4 py-2">Arrival Time</th>
                    <th className="px-4 py-2">Departure Time</th>
                    <th className="px-4 py-2">Destination / Time</th>
                    <th className="px-4 py-2">End Station / Time</th>
                    <th className="px-4 py-2">Frequency</th>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Type</th>
                  </tr>
                </thead>
                {schema.map((item) => (
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">{item.station}</td>
                      <td className="px-4 py-2">{item.arrival_time}</td>
                      <td className="px-4 py-2">{item.departure_time}</td>
                      <td className="px-4 py-2">{item.destination}</td>
                      <td className="px-4 py-2">{item.endStationTime}</td>
                      <td className="px-4 py-2">{item.end_station}</td>
                      <td className="px-4 py-2">{item.frequency}</td>
                      <td className="px-4 py-2">{item.name}</td>
                    </tr>
                    <tr className="bg-gray-300">
                      <td className="px-4 py-2" colSpan={3}>
                        Available Classes:{" "}
                        {item.available_classes.map((item) => (
                          <span className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded ml-1">
                            {item}
                          </span>
                        ))}
                      </td>
                      <td className="px-4 py-2" colSpan={3}>
                        Train ends at {item.train_ends_at}
                      </td>

                      <td className="px-4 py-2" colSpan={2}>
                        {" "}
                        Train No: {item.train_no}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleTable;
