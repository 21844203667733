import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
${({ buttontype }) => {
    let styles;
    switch (buttontype) {
        case 'LoginButton':
            styles = {
                height: '60px',
                width: '340px',
            };
            break;
        case 'ModalButton':
            styles = {
                height: '40px',
                width: '200px',
            };
            break;
        default:
            styles = {};
    }
    return styles;
}}
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  border-radius: 10px !important;
  background-color: #4277ff !important;
  border: 3px solid #1e5dff !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;

  &:hover {
    backgroundColor: "#1e5dff !important",
  },
`;
