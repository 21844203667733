export const setLoginCredentials = () => {
    localStorage.setItem('user', 'admin');
};

export const removeLoginCredentials = () => {
    localStorage.removeItem('user');
};

export const getLoginCredentials = () => {
    return localStorage.getItem('user');
};
