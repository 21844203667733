import "./App.css";
import { Dashboard, LoginPage } from "./pages";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Home from "./pages/dashboard/Home/Home";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
