import DashboardFrame from '../common';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import DirectionsSubwayIcon from '@mui/icons-material/DirectionsSubway';
import TableFrame from '../../../components/table';
import CustomButton from '../../../components/button';
import * as AdminStyled from './styles';
import React, { useEffect, useState } from 'react';
import { tableClNames, tableData } from './values';
import { getLoginCredentials } from '../../../cache';
import { useNavigate } from 'react-router-dom';

const NavItems = [
    {
        name: 'Train Schedule',
        element: <OnDeviceTrainingIcon sx={{ color: '#4277ff' }} />,
    },
    {
        name: 'Add New Train',
        element: <DirectionsSubwayIcon sx={{ color: '#4277ff' }} />,
    },
];

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(getLoginCredentials());
        if (getLoginCredentials()) {
        } else {
            navigate('/');
        }
    });

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []); /* check : Loader functioning*/

    const RenderContent = () => {
        return (
            <>
                <TableFrame columNames={tableClNames} tableBodyData={tableData} loading={loading} />
                <AdminStyled.StyledButtonWrapper>
                    {!loading ? (
                        <>
                            <CustomButton
                                buttontype="ModalButton"
                                sx={{ marginRight: '50px' }}
                                title="UpDate train data"
                                onClick={() => {
                                    /*  update  handler */
                                }}
                            />
                        </>
                    ) : null}
                </AdminStyled.StyledButtonWrapper>
            </>
        );
    };

    return (
        <div>
            <DashboardFrame navItems={NavItems} content={<div>{RenderContent()}</div>} title="Active Train Schedule" />
        </div>
    );
};

export default Dashboard;
