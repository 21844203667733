import React, { useState } from "react";
import TrainSearchForm from "./widgets/TrainSearchForm";
import ScheduleTable from "./widgets/ScheduleTable";

function Home() {
  const [show, setShow] = useState(true);
  const [startStation, setStartStation] = useState("");
  const [endStation, setEndStation] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [searchDate, setSearchDate] = useState(new Date());

  const handleSubmit = (e) => {
    // Do something with the form data
  };

  const handleShow = (bool) => {
    setShow(bool);
  };

  return (
    <div>
      <img
        src={`https://eservices.railway.gov.lk/schedule/images/top_bar_bg.png`}
        alt="Train Search Form"
        className="w-full center bg-repeat bg-cover h-[100px]"
      />
      <div className="text-left text-lg font-normal mb-4 bg-[#133785] py-2 px-5 text-white">
        Sri Lanka Railways Home
      </div>
      {show ? (
        <TrainSearchForm
          handleShow={handleShow}
          handleSubmit={handleSubmit}
          startStation={startStation}
          setStartStation={setStartStation}
          endStation={endStation}
          setEndStation={setEndStation}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          searchDate={searchDate}
          setSearchDate={setSearchDate}
        />
      ) : (
        <ScheduleTable handleShow={handleShow} />
      )}
    </div>
  );
}

export default Home;
