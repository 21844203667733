import React, { useState } from "react";

const TrainSearchForm = ({
  handleShow,
  handleSubmit,
  startStation,
  setStartStation,
  endStation,
  setEndStation,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  searchDate,
  setSearchDate,
}) => {
  // temp state
  const [tempStartStation, setTempStartStation] = useState(startStation);
  const [tempEndStation, setTempEndStation] = useState(endStation);
  const [tempStartTime, setTempStartTime] = useState(startTime);
  const [tempEndTime, setTempEndTime] = useState(endTime);
  const [tempSearchDate, setTempSearchDate] = useState(searchDate);

  const handleSearch = (e) => {
    setStartStation(tempStartStation);
    setEndStation(tempEndStation);
    setStartTime(tempStartTime);
    setEndTime(tempEndTime);
    setSearchDate(tempSearchDate);
    handleShow(false);
  };

  return (
    <div className="">
      <div className="mx-10 md:mx-40 mt-10 shadow-sm p-10 box-border rounded-md shadow-black">
        <div className="text-2xl font-bold mb-4">Search Train</div>
        <div className="my-div border-dotted border-2 border-gray-300"></div>

        <div className="shadow-sm rounded-md shadow-black p-10 mt-5">
          <div className="flex flex-col md:flex-row md:gap-10 ">
            <div className="mb-4 w-full">
              <label
                htmlFor="startStation"
                className="block text-gray-700 font-bold mb-2"
              >
                Start Station *
              </label>
              <input
                type="text"
                id="startStation"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={tempStartStation}
                onChange={(e) => setTempStartStation(e.target.value)}
                required
              />
            </div>
            <div className="mb-4 w-full">
              <label
                htmlFor="endStation"
                className="block text-gray-700 font-bold mb-2"
              >
                End Station *
              </label>
              <input
                type="text"
                id="endStation"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={tempEndStation}
                onChange={(e) => setTempEndStation(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row  md:gap-10 ">
            <div className="mb-4 w-full">
              <label
                htmlFor="startTime"
                className="block text-gray-700 font-bold mb-2"
              >
                Start Time
              </label>
              <input
                type="time"
                id="startTime"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={tempStartTime}
                onChange={(e) => setTempStartTime(e.target.value)}
              />
            </div>
            <div className="mb-4 w-full">
              <label
                htmlFor="endTime"
                className="block text-gray-700 font-bold mb-2"
              >
                End Time
              </label>
              <input
                type="time"
                id="endTime"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={tempEndTime}
                onChange={(e) => setTempEndTime(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row  md:gap-10 md:mr-10">
            <div className="mb-4 w-full md:w-1/2">
              <label
                htmlFor="searchDate"
                className="block text-gray-700 font-bold mb-2"
              >
                Search Date
              </label>
              <input
                type="date"
                id="searchDate"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={tempSearchDate}
                onChange={(e) => setTempSearchDate(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row  gap-10 ">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                handleShow(false);
                handleSearch();
              }}
            >
              Search
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainSearchForm;
