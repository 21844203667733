import * as Styled from './styles';

const CustomTextField = ({ type, placeHolder, id, onChange }) => {
    const password = type === 'PasswordTextField' ? 'password' : '';
    return (
        <Styled.CustomTextField
            id={id}
            type={password}
            styleType={type}
            placeholder={placeHolder}
            onChange={onChange}
            sx={{
                '& .Mui-focused': {
                    outline: 'none',
                    boxShadow: 'none !important',
                    borderColor: 'transparent !important',
                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4277ff  !important',
                    },
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#1e5dff  !important',
                },
            }}
        />
    );
};

export default CustomTextField;
